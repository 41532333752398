import Hashids from "hashids";

export const ellipsis = (str, length) => {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};

export const getSuffix = (filename) => {
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};

//生成hashId
export const getHashids = (...args) => {
  return new Hashids(...args);
};

//获取图片尺寸
export const getImageSize = (file) => {
  return new Promise((resolve) => {
    if (!file.type.startsWith("image/")) {
      return resolve(null);
    }
    try {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file); //根据图片路径读取图片
      fileReader.onload = function (e) {
        let img = new Image();
        img.src = this.result;
        img.onload = function () {
          return resolve({
            w: img.naturalWidth,
            h: img.naturalHeight,
          });
        };
        img.onerror = function () {
          return resolve(null);
        };
      };
    } catch (e) {
      return resolve(null);
    }
  });
};

//生成唯一id
export const getRandomStr = (prefix) => {
  return prefix + (Math.random() + "").replace(/\D/g, "");
};

export const formatCode = (code) => {
  let province = code.substr(0, 2).concat("0000");
  let city = code.substr(0, 4).concat("00");
  let area = code;
  return [province, city, area];
};

export const isEmptyVal = (val) => {
  return val === "" || val == null;
};

//生成唯一id
export const getUniqueId = (prefix = "xz") => {
  return prefix + (Math.random() + "").replace(/\D/g, "");
};

export const getOptionsLabelByValue = (options, value) => {
  let result = options.find((item) => item.value === value);
  return result ? result.label : "";
};

//px单位转换成rem单位
export const px2rem = (pxVal) => {
  return parseFloat(pxVal) / 100 + "rem";
};
