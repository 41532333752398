
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import useIntervalSwitch from "@/use/useIntervalSwitch";
import Lock from "@/components/GuideSettingPassword.vue";

export default defineComponent({
  components: {},
  setup() {
    // 监听切换系统和组织
    useIntervalSwitch();

    return {
      zhCN,
    };
  },
});
