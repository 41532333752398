import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    meta: {
      title: "授权",
      code: "v755bz8m",
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/layout/system.vue"),
    children: [
      {
        path: "/auth/authorize",
        meta: {
          title: "应用市场授权查询",
          code: "5wtv8nj6",
        },
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "@/views/auth/authorize/index.vue"
          ),
      },
    ],
  },
];

export default routes;
