import { App } from "vue";
import IconFont from "./IconFont";
import BaseSelect from "./BaseSelect/index.vue";
import TableQueryForm from "./TableQueryForm.vue";
import TableToolbar from "./TableToolbar/index.vue";
import TableWrap from "./TableWrap.vue";

// 注册全局组件
export default {
  install: (app: App) => {
    app.component("IconFont", IconFont);
    app.component(BaseSelect.name, BaseSelect);
    app.component(TableQueryForm.name, TableQueryForm);
    app.component(TableToolbar.name, TableToolbar);
    app.component(TableWrap.name, TableWrap);
    return app;
  },
};

declare module "@vue/runtime-core" {
  export interface GlobalComponents {
    IconFont: typeof import("@/components/IconFont")["default"];
    BaseSelect: typeof import("@/components/BaseSelect/index.vue")["default"];
    TableWrap: typeof import("@/components/TableWrap.vue")["default"];
    TableQueryForm: typeof import("@/components/TableQueryForm.vue")["default"];
    TableToolbar: typeof import("@/components/TableToolbar.vue")["default"];
  }
}
