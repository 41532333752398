import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import user, { State as UserState } from "./modules/user";
import permission, { State as PermissionState } from "./modules/permission";

export interface State {
  user: UserState;
  permission: PermissionState;
}

declare module "@vue/runtime-core" {
  // 为 `this.$store` 提供类型声明
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

export default createStore<State>({
  modules: {
    user,
    permission,
  },
});

export const key: InjectionKey<Store<State>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}
