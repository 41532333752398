// mock 相关
const mockOrigin = {
  rap2: "http://192.168.1.157:8080/app/mock/",
  yapi: "http://192.168.1.158:3000/mock/",
};

interface GetMockUrlOptions {
  url: string;
  method: string;
  platform?: "rap2" | "yapi";
  repositoryId?: number;
}
const getMockUrl = ({
  repositoryId = 43,
  url,
  platform = "rap2",
  method,
}: GetMockUrlOptions) => {
  if (platform === "rap2") {
    return mockOrigin[platform] + repositoryId + "/" + method + url;
  } else if (platform === "yapi") {
    return mockOrigin[platform] + repositoryId + url;
  }
};

const ApiInfos = [
  {
    key: "/",
    service: "/api/gmcgateway",
    dev: "http://192.168.100.115:8080",
    // dev: "http://192.168.100.3:8081",
  },
  {
    key: "/xzcloud",
    service: "",
    dev: " http://saastest.xzintl.com/api/gmcgateway/xzcloud/",
  },
];

const genMap = ({ origin = "https://saas.xzintl.com", isDev = false } = {}) => {
  return ApiInfos.reduce((result, { key, service, dev }) => {
    result[key] = isDev ? [dev] : [origin + service];
    return result;
  }, {} as Record<string, any>);
};

const CONFIG: Record<string, any> = {
  dev: {
    apiUrl: genMap({ isDev: true }),
  },
  ldev: {
    apiUrl: genMap({ origin: "http://saas.devxz.com" }),
  },
  beta: {
    apiUrl: genMap({ origin: "https://saastest.xzintl.com" }),
  },
  prod: {
    apiUrl: genMap({ origin: "https://saas.xzintl.com" }),
  },
  prod_yfl: {
    apiUrl: genMap({ origin: "https://saas.eifini.com" }),
  },
  prod_abc: {
    apiUrl: genMap({ origin: "https://saas.qbabckids.com.cn" }),
  },
};

const { VUE_APP_API_ENV } = process.env;

const config = CONFIG[VUE_APP_API_ENV as string];

if (!config) {
  throw new Error(`请检查环境配置，当前环境：[${VUE_APP_API_ENV}]`);
} else {
  console.log("VUE_APP_API_ENV=", VUE_APP_API_ENV);
}

export interface GetUrlOptions {
  /** 请求方式 */
  method: string;
  /** mock平台 rap2 | yapi */
  _mockPlatform?: "rap2" | "yapi";
  /** 是否开启mock */
  _mock?: boolean;
  /** mock仓库id */
  _mockRepositoryId?: number;
}

export const getUrl = (url: string, options?: GetUrlOptions) => {
  if (/^https?:\/\//.test(url)) {
    return url;
  }

  // 使用mock接口
  if (VUE_APP_API_ENV.indexOf("prod") == -1 && options?._mock === true) {
    return getMockUrl({
      url,
      method: options.method,
      platform: options._mockPlatform,
      repositoryId: options._mockRepositoryId,
    });
  }

  let domain = "";
  for (const key in config.apiUrl) {
    if (url.indexOf(key) === 0) {
      domain =
        config.apiUrl[key][
          Math.floor(Math.random() * config.apiUrl[key].length)
        ];
      break;
    }
  }
  return domain + url;
};
