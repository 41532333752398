import { Module } from "vuex";
import { RouteRecordRaw } from "vue-router";
import { constantRoutes } from "@/router/index";
import { State as RootState } from "../index";

// 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
function hasPermission(route, authorityCodeList) {
  if (!route?.meta?.code || authorityCodeList.indexOf(route.meta.code) > -1) {
    return true;
  }
  return false;
}

export const filterAsyncRouter = (
  routes: Array<RouteRecordRaw>,
  authorityCodeList: string[]
) => {
  return routes.map((route) => {
    let { children, meta = {} } = route;
    if (children && children.length > 0) {
      children = filterAsyncRouter(children, authorityCodeList);
    }
    let chidlrenLen = children?.filter((it) => it.meta?.hide !== true)?.length;
    if (chidlrenLen && chidlrenLen > 0) {
      return {
        ...route,
        children,
      };
    }
    if (!hasPermission(route, authorityCodeList)) {
      return {
        ...route,
        children,
        meta: { ...meta, hide: true },
        component: () => import("@/views/exception/403.vue"),
      };
    }
    return {
      ...route,
      children,
    };
  });
};

export interface State {
  routers: Array<RouteRecordRaw>;
  addRouters: Array<RouteRecordRaw>;
}

export default {
  namespaced: true,
  state: {
    routers: constantRoutes,
    addRouters: [],
  },
  mutations: {
    SET_ROUTERS: (state, data) => {
      state.addRouters = data;
      state.routers = constantRoutes.concat(data);
    },
  },
  actions: {
    // 动态添加主界面路由，需要缓存
    UpdateAppRouter({ commit }, routes) {
      return new Promise((resolve) => {
        commit("SET_ROUTERS", routes);
        resolve("路由添加成功");
      });
    },
  },
} as Module<State, RootState>;
