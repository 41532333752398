import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/test",
    meta: {
      title: "测试",
      code: "izdt0pgi",
      needLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "system" */ "@/views/test/index.vue"),
  },
];

export default routes;
