import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import router from "@/router";
import store from "@/store";
import setting from "@/config/setting";
import { asyncRoutes, constantRoutesPaths } from "@/router/index";
import { packRoutes } from "@/router/util";
import { GetCurrentUserRes } from "@/services/user";
import { filterAsyncRouter } from "@/store/modules/permission";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  if (setting.takeToken()) {
    /* has token */
    if (to.path === setting.loginPath) {
      next({ path: setting.homePath });
      NProgress.done();
    } else if (constantRoutesPaths.indexOf(to.path) > -1) {
      // 静态路由直接进入
      next();
    } else {
      const { user, permission } = store.state as any;
      console.log("user.permission=", user.permission);
      // 判断权限;
      if (!user.permission) {
        store
          .dispatch("user/GetPermission")
          .then((data: GetCurrentUserRes) => {
            const routes = packRoutes(asyncRoutes);
            // // 添加主界面路由
            store.dispatch("permission/UpdateAppRouter", routes).then(() => {
              // 动态添加可访问路由表
              router.addRoute({
                name: "BasicLayout",
                path: "/",
                redirect: permission.addRouters[0].path,
                component: () => import("@/layout/basic.vue"),
                children: permission.addRouters,
              });
              const redirect = decodeURIComponent(
                (from.query.redirect as string) || to.path
              );
              if (to.path === redirect) {
                // hack方法 确保addRouter已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true });
              } else {
                // 跳转到目的路由
                next({ path: redirect });
              }
            });
          })
          .catch(() => {
            store.dispatch("user/Logout").then(() => {
              next({ path: "/user/login", query: { redirect: to.fullPath } });
            });
          });
      } else {
        next();
      }
    }
  } else {
    if (
      setting.whiteList.indexOf(to.path) !== -1 ||
      to.meta?.needLogin === false
    ) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({ path: "/user/login", query: { redirect: to.path, ...to.query } });
      NProgress.done();
    }
  }
});

router.afterEach((to) => {
  // 修改标题
  if (to.meta?.title) document.title = to.meta.title;
  NProgress.done(); // finish progress bar
});
