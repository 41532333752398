import { onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { Modal } from "ant-design-vue";
import setting from "../config/setting";

const useIntervalSwitch = () => {
  const route = useRoute();

  let timer;
  let { teamId } = setting.takeTeam() || {};
  let { applicationId } = setting.takeApply() || {};

  const showModal = (content: string) => {
    clearInterval(timer);
    Modal.warning({
      content,
      okText: "确定",
      onOk() {
        window.location.href = "/system/selectSystem";
        intervalSwitch();
      },
    });
  };

  const isWhitePage = () => {
    return (
      route.path === setting.loginPath || route.path === "/system/selectSystem"
    );
  };

  const intervalSwitch = () => {
    timer = setInterval(() => {
      let { teamId: newTeamId } = setting.takeTeam() || {};
      // 判断团队
      if (teamId != newTeamId) {
        if (window.__teamId__ == newTeamId || isWhitePage()) {
          teamId = newTeamId;
        } else {
          showModal("你已切换到其他团队，需要刷新后才能继续操作。");
          return;
        }
      }

      let { applicationId: newApplicationId } = setting.takeApply() || {};
      // 判断应用
      if (applicationId != newApplicationId) {
        if (window.__applicationId__ == newApplicationId || isWhitePage()) {
          applicationId = newApplicationId;
        } else {
          showModal("你已切换到其他应用，需要刷新后才能继续操作。");
          return;
        }
      }
    }, 1000);
  };
  // 切换检测
  onMounted(() => {
    intervalSwitch();
  });
  onUnmounted(() => {
    clearInterval(timer);
  });
};

export default useIntervalSwitch;
