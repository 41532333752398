import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import user from "./user";
import system from "./system";
import test from "./test";
import global from "./global/index";
import cloud from "./cloud/index";
import auth from "./auth";

declare module "vue-router" {
  interface RouteMeta {
    title?: string;
    hide?: boolean;
    code: string;
    needLogin?: boolean;
    /* 隐藏头部导航 */
    hideHeader?: boolean;
    /* 隐藏左侧导航  */
    hideSider?: boolean;
  }
}

export const constantRoutes: Array<RouteRecordRaw> = [
  ...user,
  ...system,
  ...test,
  ...auth,
  {
    path: "/404",
    meta: {
      title: "404",
    } as any,
    component: () => import("@/views/exception/404.vue"),
  },
  {
    path: "/403",
    meta: {
      title: "403",
    } as any,
    component: () => import("@/views/exception/403.vue"),
  },
  {
    path: "/:w+",
    meta: {
      title: "404",
    } as any,
    component: () => import("@/views/exception/404.vue"),
  },
];

const getRoutePaths = (routes) => {
  return routes.reduce((res, route) => {
    return [...res, route.path, ...getRoutePaths(route.children || [])];
  }, []);
};

export const constantRoutesPaths = getRoutePaths(constantRoutes);

export const asyncRoutes = [...cloud];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});

export default router;

const { VUE_APP_API_ENV } = process.env;

if (VUE_APP_API_ENV.indexOf("prod") == -1) {
  // 校验是否有重复code
  checkRouteRepeatCode();
}

function checkRouteRepeatCode() {
  const temp = {};
  function traverse(routes) {
    routes.forEach((route) => {
      const code = route.meta?.code;
      if (code) {
        temp[code] = (temp[code] || 0) + 1;
      }
      if (route.children?.length) {
        traverse(route.children);
      }
    });
  }
  traverse(asyncRoutes);
  const repeatCode = Object.keys(temp)
    .filter((code) => {
      return temp[code] > 1;
    })
    .join("、");
  if (repeatCode.length > 0) {
    console.error("路由配置存在重复code， 请检查", repeatCode);
  }
}
