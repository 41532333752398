
import { defineComponent, PropType } from "vue";
import { SyncOutlined } from "@ant-design/icons-vue";
import Pagination from "./Pagination.vue";

export default defineComponent({
  name: "TableToolbar",
  props: {
    showRefresh: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
    },
    refresh: {
      type: Function as PropType<() => any>,
      default: () => {},
    },
    selectLength: {
      type: Number,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { slots }) {
    return () => {
      const { refresh, showPagination, pagination, selectLength, showRefresh } =
        props;

      const addonBefore =
        selectLength != null ? (
          <a-space size={6} style={{ color: "#393C42" }}>
            <span>已选</span>
            <span style={{ color: "#1969F1", fontWeight: "bold" }}>
              {selectLength}
            </span>
            <span>项</span>
          </a-space>
        ) : null;

      return (
        <a-row>
          <a-col flex="auto">
            <a-space size={10}>
              {slots.default?.()}
              {showRefresh && (
                <a-button
                  onClick={refresh}
                  icon={
                    <SyncOutlined style={{ fontSize: 12, color: "#8A909F" }} />
                  }
                />
              )}
            </a-space>
          </a-col>
          {(showPagination || !!addonBefore) && (
            <a-col
              flex={showPagination ? "480px" : "120px"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                {...({
                  ...pagination,
                  showPagination,
                } as any)}
                v-slots={{
                  addonBefore,
                }}
              ></Pagination>
            </a-col>
          )}
        </a-row>
      );
    };
  },
});
