/**
 * axios配置
 */
import type { App } from "vue";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { Modal, message } from "ant-design-vue";
import store from "@/store";
import router from "@/router";
import setting from "./setting";
import { getUrl, GetUrlOptions } from "./apiurl-config";
import { __bl } from "@/utils/monitor";

const reportError = ({
  requestUrl,
  responseData,
  responseHeader,
  requestData,
  requestHeader,
}) => {
  // 处理请求头
  requestHeader = { ...(requestHeader || {}) };
  const headers = setting.getRequestHeader();
  for (let key in headers) {
    requestHeader[key] = headers[key];
  }

  __bl.error({
    name: "ApiResultCodeError",
    message: JSON.stringify({
      message: responseData.message,
      code: responseData.code,
      traceid: responseHeader["eagleeye-traceid"],
      url: getUrl(requestUrl),
      requestHeader,
      requestData,
    }),
  });
};

const { VUE_APP_API_ENV } = process.env;

export interface PromiseResult<T> {
  code: number;
  data: T;
  message: string;
}

const axiosI = axios.create();

/* 请求拦截器 */
axiosI.interceptors.request.use(
  (config) => {
    // 自定义请求头
    const headers = setting.getRequestHeader();
    Object.keys(headers).forEach((key) => {
      config.headers[key] = headers[key];
    });

    const options: GetUrlOptions = {
      method: config.method!,
    };

    const fetchData =
      config.method === "get"
        ? config?.params
        : config.method === "post"
        ? config?.data
        : {};

    // 开发和测试环境使用mock功能
    if (VUE_APP_API_ENV.indexOf("prod") == -1 && fetchData?._mock === true) {
      options._mockPlatform = fetchData?._mockPlatform;
      options._mock = true;
      options._mockRepositoryId = fetchData?._mockRepositoryId;
    } else {
      // 正式环境删除mock参数
      delete fetchData?._mockPlatform;
      delete fetchData?._mock;
      delete fetchData?._mockRepositoryId;
    }

    config.url = getUrl(config.url!, options);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* 响应拦截器 */
axiosI.interceptors.response.use(
  (res) => {
    // 登录过期处理
    if (res.data.code === 401) {
      goLogin(res.data?.message);
      return Promise.reject(new Error(res.data?.message));
    }
    // 团队ID异常
    if (res.data.code === 5000) {
      message.error("请先选择团队～");
      router.push({
        path: "/system/selectSystem",
      });
      return Promise.reject(new Error(res.data?.message));
    }
    // 应用ID异常
    if (res.data.code === 5001) {
      message.error("请先选择应用～");
      router.push({
        path: "/system/applyCenter",
      });
      return Promise.reject(new Error(res.data?.message));
    }
    // token自动续期
    // const access_token = res.headers[setting.tokenHeaderName];
    // if (access_token) {
    //   setting.cacheToken(access_token);
    // }
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function httpCallback<T>({
  res: { status, data, headers },
  resolve,
  reject,
  options,
  requestUrl,
  requestHeader,
  requestData,
}: {
  res: AxiosResponse<PromiseResult<T>>;
  resolve:
    | ((value: T | PromiseLike<T>) => void)
    | ((value: PromiseResult<T> | PromiseResult<PromiseLike<T>>) => void);
  reject: (reason?: any) => void;
  options?: OptionsWithFull;
  requestUrl: string;
  requestHeader: any;
  requestData: any;
}) {
  try {
    if (
      (!headers["Content-Type"] ||
        headers["Content-Type"]?.indexOf("application/json") > -1) &&
      data.code != 200
    ) {
      // 上报错误信息
      reportError({
        requestUrl,
        responseData: data,
        responseHeader: headers,
        requestData,
        requestHeader,
      });
    }
  } catch (e) {
    console.error(e);
  }

  const result: any = options?.full ? data : data.data;
  if (
    headers["content-type"].indexOf("image") > -1 ||
    headers["content-type"].indexOf("application/octet-stream") > -1 ||
    headers["content-type"].indexOf("application/pdf") > -1
  ) {
    return resolve(result);
  }
  if ((status === 200 && +data.code === 200) || data.code === 1) {
    return resolve(result);
  }
  if (
    data.code === 500001 ||
    data.code === 500002 ||
    data.code === 500003 ||
    data.code === 500004 ||
    data.code === 500005
  ) {
    // message.error(data.message || "请求失败～");
    // goLogin(true);
    Modal.destroyAll();
    Modal.info({
      title: "系统提示",
      content: "登录状态已过期, 请退出重新登录!",
      okText: "重新登录",
      onOk: () => {
        goLogin();
      },
    });
    return;
    // aiHistory.replace({ pathname: LoginPath });
  }
  if (data.code === 500009) {
    Modal.destroyAll();
    Modal.warning({
      title: "授权提示",
      content: "系统未授权，请先授权!",
      okText: "前往授权",
      onOk: () => {
        router.push({
          path: "/setting/trilateralAuthorize/enterpriseWechat",
        });
      },
    });
    return;
  }
  message.error(data.message || "请求失败～");
  reject(data);
}

interface Options {
  full?: true | false;
}
interface OptionsWithFull extends Options {
  full: true;
}

function httpGet<T>(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig
): Promise<T>;
function httpGet<T>(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: OptionsWithFull
): Promise<PromiseResult<T>>;
function httpGet<T>(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: Options
): Promise<T>;
function httpGet<T>(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: any
): any {
  return http<T>({ url, method: "get", data: params, config, options });
}
export { httpGet };

function httpPost<T>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig
): Promise<T>;
function httpPost<T>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: OptionsWithFull
): Promise<PromiseResult<T>>;
function httpPost<T>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: Options
): Promise<T>;
function httpPost<T>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig,
  options?: any
) {
  return http<T>({ url, method: "post", data, config, options });
}

export { httpPost };

export let goingLogin = { value: false };

const goLogin = (errMsg?: string) => {
  // 多个接口同时返回相同错误，处理重复处理问题
  if (goingLogin.value) return;
  goingLogin.value = true;
  if (errMsg) {
    message.error(errMsg);
  }
  store.dispatch("user/Logout").then(() => {
    router.push({
      path: "/user/login",
      query: { redirect: router.currentRoute.value?.fullPath || "/" },
    });
  });
};

function http<T>({
  url,
  method,
  data,
  config,
  options,
}: {
  url: string;
  method: "get" | "post";
  data?: Record<string, any>;
  config?: AxiosRequestConfig;
  options?: any;
}) {
  return new Promise<T>((resolve, reject) => {
    const requestHeader = config?.headers || {};
    const requestData = data;

    const p =
      method === "get"
        ? axiosI.get(url, {
            params: requestData,
            ...config,
          })
        : axiosI.post(url, requestData, config);

    p.then((res) => {
      httpCallback({
        res,
        resolve,
        reject,
        options,
        requestUrl: url,
        requestHeader,
        requestData,
      });
    }).catch((err) => {
      // 登录相关错误
      if (err?.response?.status > 300 && err?.response?.status <= 401) {
        // 多个接口同时返回相同错误，处理重复处理问题
        goLogin(err?.response?.data?.message);
      } else {
        if (err?.message === "Network Error") {
          message.error("网络异常，请稍后重试～");
        }
        reject(err);
      }
    });
  });
}

export default {
  install(app: App<Element>) {
    app.config.globalProperties.$httpGet = httpGet;
    app.config.globalProperties.$httpPost = httpPost;
    app.config.globalProperties.$axios = axios;
  },
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $httpGet: typeof httpGet;
    $httpPost: typeof httpPost;
    $axios: typeof axios;
  }
}
