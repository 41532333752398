import { createApp } from "vue";
import * as xzUse from "xz-use";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import "@/utils/monitor";
import store, { key } from "./store";
// 全部配置
import globalPropertiesConfig from "./config/globalProperties";
// axios
import axiosConfig from "./config/axios-config";
// 图片
import imgConfig from "./config/img-config";
// 自定义组件
import components from "@/components/index";
// 权限
import "@/permission"; // permission control
// 格式化样式
import "normalize.css";
// antd
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
// 全局样式
import "@/assets/style/index.less";

xzUse.init({
  useCountDown: {
    dateFn: moment,
  },
  useRequest: {
    throwOnError: true,
  },
  useAntdTable: {
    defaultPageSize: 20,
    replacePageFields: {
      pageSize: "size",
    },
  },
});

const app = createApp(App);
app.use(store, key);
app.use(router);
app.use(Antd);
app.use(globalPropertiesConfig);
app.use(imgConfig);
app.use(axiosConfig);
app.use(components);
app.mount("#app");
