
import { defineComponent, PropType, ref } from "vue";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { LeftOutlined, RightOutlined },
  props: {
    current: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    onChange: {
      type: Function as PropType<(current: number, pageSize: number) => void>,
      required: true,
    },
    changeCurrent: {
      type: Function as PropType<(current: number) => void>,
      required: true,
    },
    changePageSize: {
      type: Function as PropType<(pageSize: number) => void>,
      required: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { slots }) {
    const inputRef = ref<HTMLInputElement>();
    const value = ref(props.current);
    const handleJump = (current: string) => {
      const { totalPage, changeCurrent } = props;

      if (current) {
        let newCurrent = Number(current);
        if (typeof newCurrent === "number" && !isNaN(newCurrent)) {
          newCurrent = parseInt(newCurrent + "");
          newCurrent =
            newCurrent < 1
              ? 1
              : newCurrent > totalPage
              ? totalPage
              : newCurrent;
          changeCurrent(newCurrent);
          // 填的小数
          if (newCurrent !== +current) {
            value.value = newCurrent;
          }
        }
      } else {
        value.value = 1;
      }
    };

    return {
      value,
      inputRef,
      handleJump,
    };
  },
});
