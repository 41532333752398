
import { onMounted, onUnmounted, onUpdated, ref } from "vue";
import { useToggle, useThrottleFn } from "xz-use";
import { DoubleRightOutlined } from "@ant-design/icons-vue";
import { formProps } from "ant-design-vue/es/form";

const lineHeight = 40;

export default {
  name: "TableQueryForm",
  props: {
    ...formProps,
    onReset: Function,
    onSubmit: Function,
    search: Object,
    buttonColWidth: {
      type: String,
      default: null,
    },
  },
  setup(props, { expose, attrs, slots }) {
    const formRef = ref();
    const contentRef = ref();
    const multiLine = ref(false);
    const contentHeightRef = ref(0);
    const { state: showMore, toggle: toggleShowMore } = useToggle(false);

    const { run: changeMultiLine } = useThrottleFn(() => {
      if (contentRef.value && contentRef.value.offsetHeight > lineHeight) {
        multiLine.value = true;
      } else {
        multiLine.value = false;
      }

      if (contentRef.value) {
        const { offsetHeight } = contentRef.value;
        contentHeightRef.value = offsetHeight;
        if (offsetHeight > 59) {
          multiLine.value = true;
          return;
        }
      }
      multiLine.value = false;
    });

    expose({
      resetFields: (...args) => formRef.value?.resetFields(...args),
    });

    onMounted(() => {
      changeMultiLine();
      window.addEventListener("resize", changeMultiLine, false);
    });

    onUpdated(() => {
      changeMultiLine();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", changeMultiLine, false);
    });

    return () => {
      const { onReset, onSubmit, search, buttonColWidth, ...formProps } = props;

      return (
        <div
          class="query-form-container"
          style={{
            height: showMore.value
              ? `${contentHeightRef.value}px`
              : `${lineHeight}px`,
            overflow: "hidden",
          }}
        >
          <div ref={contentRef}>
            <a-form ref={formRef} {...formProps} {...attrs} layout="inline">
              <a-row style={{ width: "100%", flexWrap: "nowrap" }}>
                <a-col flex="auto" style="display:flex;flex-wrap:wrap;">
                  {slots.default?.()}
                </a-col>
                <a-col
                  flex={buttonColWidth || (multiLine.value ? "170px" : "110px")}
                >
                  <a-space size={30} style={`line-height:${lineHeight}px;`}>
                    {slots.button ? (
                      slots.button()
                    ) : (
                      <a-space size={12}>
                        <a-button
                          type="primary"
                          html-type="submit"
                          onClick={onSubmit || search?.submit}
                        >
                          查询
                        </a-button>
                        <a-button onClick={onReset || search?.reset}>
                          重置
                        </a-button>
                      </a-space>
                    )}
                    {multiLine.value && (
                      <DoubleRightOutlined
                        style={{
                          fontSize: "12px",
                          transition: "transform .5s",
                          transform: `rotate(${
                            showMore.value ? "-90deg" : "90deg"
                          })`,
                        }}
                        onClick={() => toggleShowMore()}
                      />
                    )}
                  </a-space>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>
      );
    };
  },
};
