import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/cloud",
    meta: {
      title: "象征云",
      code: "dfqxajve",
    },
    component: RouterView,
    children: [
      {
        path: "/cloud",
        meta: {
          title: "象征云",
          icon: "iconcloud-server",
          code: "iw2eqq5x",
        },
        component: RouterView,
        children: [
          {
            path: "/cloud/overview",
            meta: {
              title: "应用概况",
              code: "ddd8nkx2",
            },
            component: RouterView,
            children: [
              {
                path: "/cloud/overview/home",
                meta: {
                  title: "应用概况",
                  code: "81ouinpb",
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/overview/home/index.vue"
                  ),
              },
            ],
          },
          {
            path: "/cloud/systemIntegration",
            meta: {
              title: "系统集成",
              code: "bnip9nlg",
            },
            component: RouterView,
            children: [
              {
                path: "/cloud/systemIntegration/authManage/list",
                meta: {
                  title: "授权管理",
                  code: "7znlfezh",
                  applicationType: 1,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/systemIntegration/authManage/list/index.vue"
                  ),
              },
              // 自研
              {
                path: "/cloud/systemIntegration/authManageZy/list",
                meta: {
                  title: "授权管理",
                  code: "a9mx599n",
                  applicationType: 2,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/systemIntegration/authManageZy/list/index.vue"
                  ),
              },
              {
                path: "/cloud/systemIntegration/tripartiteSystem/list",
                meta: {
                  title: "三方系统",
                  code: "ft3zqx52",
                  applicationType: 1,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/systemIntegration/tripartiteSystem/list/index.vue"
                  ),
              },
            ],
          },
          {
            path: "/cloud/messagePush",
            meta: {
              title: "消息推送",
              icon: "icondianpu",
              code: "h84mbhm9",
            },
            component: RouterView,
            children: [
              {
                path: "/cloud/messagePush/messageList/list",
                meta: {
                  title: "消息列表",
                  code: "a5hwic9v",
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/messagePush/messageList/list/index.vue"
                  ),
              },
              {
                path: "/cloud/messagePush/messageSubscript/list",
                meta: {
                  title: "消息订阅",
                  code: "wjm6zcyf",
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/messagePush/messageSubscript/index.vue"
                  ),
              },
              // {
              //   path: "/cloud/messagePush/messageRecord/list",
              //   meta: {
              //     title: "消息记录",
              //     code: "jil5uap1",
              //   },
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "cloud" */ "@/views/cloud/messagePush/messageRecord/list/index.vue"
              //     ),
              // },
              // {
              //   path: "/cloud/messagePush/messageDebugger/list",
              //   meta: {
              //     title: "消息调试",
              //     code: "mtur38t0",
              //   },
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "cloud" */ "@/views/cloud/messagePush/messageDebugger/list/index.vue"
              //     ),
              // },
            ],
          },
          {
            path: "/cloud/apiSetting",
            meta: {
              title: "API配置",
              icon: "icondianpu",
              code: "l2hkg8xo",
            },
            component: RouterView,
            children: [
              {
                path: "/cloud/apiSetting/apiList/list",
                meta: {
                  title: "API列表",
                  code: "oijb5cnk",
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/apiSetting/apiList/list/index.vue"
                  ),
              },
              // {
              //   path: "/cloud/apiSetting/apiRecord/list",
              //   meta: {
              //     title: "API记录",
              //     code: "lkijdq29",
              //   },
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "cloud" */ "@/views/cloud/apiSetting/apiRecord/list/index.vue"
              //     ),
              // },
              // {
              //   path: "/cloud/apiSetting/apiDebugger/list",
              //   meta: {
              //     title: "API调试",
              //     code: "dj42p0z3",
              //   },
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "cloud" */ "@/views/cloud/apiSetting/apiDebugger/list/index.vue"
              //     ),
              // },
            ],
          },
          {
            path: "/cloud/extPoint",
            meta: {
              title: "扩展点",
              icon: "icondianpu",
              code: "ypkmp3h0",
            },
            component: RouterView,
            children: [
              {
                path: "/cloud/extPoint/extPointList/list",
                meta: {
                  title: "扩展点列表",
                  code: "1hn9anzw",
                },
                component: () =>
                  import(
                    /* webpackChunkName: "cloud" */ "@/views/cloud/extPoint/extPointList/list/index.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
