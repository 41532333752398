import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/system",
    meta: {
      title: "选择团队",
      code: "75x7tlmv",
    },
    component: () =>
      import(/* webpackChunkName: "system" */ "@/layout/system.vue"),
    children: [
      {
        path: "/system/selectRole",
        meta: {
          title: "选择角色",
          code: "9l4zaf67",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/system/selectRole/index.vue"
          ),
      },
      {
        path: "/system/selectSystem",
        meta: {
          title: "选择团队",
          code: "2qakl6gv",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/system/selectSystem/index.vue"
          ),
      },
      {
        path: "/system/applyCenter",
        name: "applyCenter",
        meta: {
          title: "应用中心",
          code: "2qakl6gv",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/system/applyCenter/index.vue"
          ),
      },
      {
        path: "/system/creatApply",
        name: "creatApply",
        meta: {
          title: "创建应用",
          code: "2qakl6gv",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/system/creatApply/index.vue"
          ),
      },
      {
        path: "/system/personalInfo",
        meta: {
          title: "个人资料",
          code: "zkky30bo",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/user/personalInfo/index.vue"
          ),
      },
    ],
  },
];

export default routes;
