import { Module } from "vuex";
import {
  login,
  LoginParams,
  getCurrentUser,
  UserInfo,
  GetCurrentUserRes,
  TeamInfo,
} from "@/services/user";
import setting from "@/config/setting";
import router from "@/router/index";
import { XzcloudTeamInfoRes } from "@/views/system/selectSystem/service";
import { State as RootState } from "../index";
import { XzcloudApplicationSelectListRes } from "@/views/system/applyCenter/service";

export interface State {
  // 团队信息
  teamInfo: TeamInfo | null;
  // 应用信息
  applyInfo: XzcloudApplicationSelectListRes | null;
  // 用户信息
  userInfo: UserInfo | null;
  // 权限信息
  permission: GetCurrentUserRes | null;
  //是否显示提示框
  modelVisible: boolean;
}

export default {
  namespaced: true,
  state: {
    // 团队信息
    teamInfo: setting.takeTeam(),
    // 应用信息
    applyInfo: setting.takeApply(),
    // 用户信息
    userInfo: setting.takeUser(),
    // 权限信息
    permission: null,
    //显示是否需要设置密码
    modelVisible: false,
  },
  getters: {
    applicationType: (state) => {
      return state.applyInfo?.type;
    },
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_TEAMINFO: (state, teamInfo) => {
      state.teamInfo = teamInfo;
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission;
    },
    SET_INFOModal: (state, modelVisible) => {
      state.modelVisible = modelVisible;
    },
    SET_APPLYINFO: (state, applyInfo) => {
      state.applyInfo = applyInfo;
    },
  },
  actions: {
    // 登录
    async Login({ commit, dispatch }, loginParams: LoginParams) {
      const data = await login(loginParams);
      // 清除缓存
      await dispatch("ClearCache");
      // 重置路由
      // router.removeRoute("BasicLayout");
      let { tokenJson, userInfo, hasPassword } = data;
      // 缓存token
      setting.cacheToken(tokenJson.accessToken, true);
      // 缓存用户信息
      setting.cacheUser(userInfo);
      commit("SET_USERINFO", userInfo);
      // if (!hasPassword) {
      //   commit("SET_INFOModal", true);
      //   setTimeout(() => {
      //     commit("SET_INFOModal", false);
      //   }, 15000);
      // }
      // router.replace("/system/selectSystem");
      return data;
    },
    // 获取权限信息
    GetPermission({ commit }) {
      return new Promise((resolve, reject) => {
        getCurrentUser()
          .then((data) => {
            commit("SET_PERMISSION", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    async Logout({ commit, state, dispatch }) {
      await dispatch("ClearCache");
      // logout()
      //   .then(() => {
      //     resolve("退出成功");
      //   })
      //   .catch(() => {
      //     resolve("退出成功");
      //   });
    },
    async CacheSwitchSysInfo(
      { commit },
      {
        teamInfo,
      }: {
        teamInfo: XzcloudTeamInfoRes;
      }
    ) {
      commit("SET_PERMISSION", null);
      commit("SET_TEAMINFO", teamInfo);
      // 更换了团队需要清空应用信息
      commit("SET_APPLYINFO", null);
      setting.cacheTeam(teamInfo); // 缓存团队信息
      window.__teamId__ = teamInfo.teamId;
      window.__applicationId__ = "";
    },
    async CacheSwitchApplyInfo(
      { commit },
      {
        applyInfo,
      }: {
        applyInfo: XzcloudApplicationSelectListRes;
      }
    ) {
      commit("SET_APPLYINFO", applyInfo);
      setting.cacheApply(applyInfo); // 缓存团队信息
      window.__applicationId__ = applyInfo.applicationId;
    },
    // 清除缓存
    async ClearCache({ commit }) {
      // 清除token
      setting.cacheToken();
      // 清除用户
      setting.cacheUser();
      setting.cacheTeam();
      setting.cacheApply();
      window.__teamId__ = "";
      window.__applicationId__ = "";
      commit("SET_APPLYINFO", null);
      commit("SET_USERINFO", null);
      // commit("SET_SYSTEMIFNO", null);
      commit("SET_PERMISSION", null);
      commit("SET_TEAMINFO", null);
      commit("SET_APPLYINFO", null);
    },
    closeModal({ commit, state, dispatch }) {
      commit("SET_INFOModal", false);
    },
  },
} as Module<State, RootState>;
