<template>
  <a-space :size="0" class="pagination">
    <a-space :size="4">
      <slot name="addonBefore"></slot>
      <a-divider v-if="$slots.addonBefore?.()" type="vertical" />
      <template v-if="showPagination">
        <a-space :size="4">
          <a-space :size="8">
            <span>共 {{ total }} 条</span>
            <span>每页</span>
            <div>
              <a-select
                :value="pageSize"
                @change="(val) => onChange(1, val)"
                class="page-size-select"
              >
                <a-select-option :value="20">20</a-select-option>
                <a-select-option :value="50">50</a-select-option>
                <a-select-option :value="100">100</a-select-option>
              </a-select>
            </div>
            <span>条</span>
          </a-space>
          <a-divider type="vertical" />
          <a-space :size="6">
            <span>至</span>
            <a-input
              ref="inputRef"
              class="jump-page-input"
              v-model:value="value"
              @blur="(e) => handleJump(e.target.value)"
              @pressEnter="() => inputRef?.blur()"
            />
            <span>页</span>
            <a-button
              class="prev-btn"
              :disabled="current === 1"
              @click="changeCurrent(current - 1)"
            >
              <template #icon>
                <LeftOutlined
                  :style="{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }"
                />
              </template>
            </a-button>
            <a-button
              class="next-btn"
              :disabled="current === totalPage || totalPage <= 1"
              @click="changeCurrent(current + 1)"
            >
              <template #icon>
                <RightOutlined
                  :style="{ fontSize: '12px', fontWeight: 'bold' }"
                />
              </template>
            </a-button>
          </a-space>
        </a-space>
      </template>
    </a-space>
  </a-space>
</template>
<script lang="tsx">
import { defineComponent, PropType, ref } from "vue";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { LeftOutlined, RightOutlined },
  props: {
    current: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    onChange: {
      type: Function as PropType<(current: number, pageSize: number) => void>,
      required: true,
    },
    changeCurrent: {
      type: Function as PropType<(current: number) => void>,
      required: true,
    },
    changePageSize: {
      type: Function as PropType<(pageSize: number) => void>,
      required: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { slots }) {
    const inputRef = ref<HTMLInputElement>();
    const value = ref(props.current);
    const handleJump = (current: string) => {
      const { totalPage, changeCurrent } = props;

      if (current) {
        let newCurrent = Number(current);
        if (typeof newCurrent === "number" && !isNaN(newCurrent)) {
          newCurrent = parseInt(newCurrent + "");
          newCurrent =
            newCurrent < 1
              ? 1
              : newCurrent > totalPage
              ? totalPage
              : newCurrent;
          changeCurrent(newCurrent);
          // 填的小数
          if (newCurrent !== +current) {
            value.value = newCurrent;
          }
        }
      } else {
        value.value = 1;
      }
    };

    return {
      value,
      inputRef,
      handleJump,
    };
  },
});
</script>
<style lang="less" scoped>
.pagination {
  color: #6c717d;
  font-size: 12px;
  &::v-deep {
    .ant-input {
      width: 48px;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      text-align: center;
    }
    .ant-select {
      font-size: 12px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 28px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 26px;
    }
    .ant-btn {
      height: 28px;
    }
  }
}
</style>
