import setting from "@/config/setting";
import BrowserLogger from "@arms/js-sdk";
import monitorEnvJson from "./monitorEnv";
import "./notLoginId";

const ignoreApiRegs = [/fundebug/, /\/xzDebugPlatform\//];

const isIgnoreApi = (data) => {
  const { api } = data || [];
  if (!api) return false;
  for (let i = 0; i < ignoreApiRegs.length; i++) {
    const ignoreApiReg = ignoreApiRegs[i];
    if (ignoreApiReg.test(api)) return true;
  }
  return false;
};

// 判断是否为开发环境
const isDevelopEnv = () => {
  return (
    /localhost/.test(window.location.host) ||
    /\b(?:\d{1,3}\.){3}\d{1,3}\b/.test(window.location.host)
  );
};

const { VUE_APP_API_ENV } = process.env;

const pid = monitorEnvJson[VUE_APP_API_ENV!];

export const __bl = BrowserLogger.singleton({
  pid,
  appType: "web",
  imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
  region: "cn",
  behavior: false,
  disableHook: false,
  autoSendPerf: false,
  tag: VUE_APP_API_ENV,
  environment: VUE_APP_API_ENV!.indexOf("prod") > -1 ? "prod" : "daily",
  release: "1.0.0",
  enableLinkTrace: true,
  enableApiCors: true,
  ignore: {
    ignoreApis: [/fundebug/, /xzDebugPlatform/],
  },
  linkType: "tracing",
  beforeReport: function (data) {
    // 开发环境先不上报
    if (!pid) return;
    // 暂时只上报错误和api
    if (data.t !== "error" && data.t !== "api") {
      return;
    }
    // 忽略部分api
    if (isIgnoreApi(data)) {
      return;
    }

    if (isDevelopEnv()) {
      // 本地环境不上报
      return;
    }

    const userInfo = setting.takeUser();
    const team = setting.takeTeam();
    const apply = setting.takeApply();
    const token = setting.takeToken();

    return {
      ...data,
      userInfo: userInfo || "",
      team: team || "",
      apply: apply || "",
      token,
    };
  },
  setUsername: function () {
    let userInfo: any = setting.takeUser();
    if (!userInfo) return null;
    return userInfo.username || userInfo.userId;
  },
});
