import moment from "moment";
import { getUniqueId } from "./index";

const key = "xzNotLoginId";

const xzNotLoginId = localStorage.getItem(key);

if (!xzNotLoginId) {
  localStorage.setItem(key, getUniqueId() + moment().format("YYYYMMDDHHmmss"));
}

export const getXzNotLoginId = () => {
  return localStorage.getItem(key);
};
