/**
 * 项目统一配置
 */
export default {
  // 路由白名单(不需要登录的)
  whiteList: ["/user/login"],
  // 不显示全局页脚的路由地址
  hideFooters: [],
  // 不显示侧边栏的路由地址
  hideSidebars: [],
  // 可重复打开页签的路由地址
  repeatableTabs: [],
  // 需要缓存的组件名称
  keepAliveList: [],
  // 菜单数据接口
  // menuUrl: "/main/menu?type=pro",
  // menuUrl: "/main/menu", // 如果使用配套后端这里不要带参数
  // 自定义解析接口菜单数据
  // parseMenu: null,
  // 自定义解析接口菜单每一个数据格式
  // parseMenuItem: null,
  // 直接指定菜单数据
  // menus: routes,
  // 用户信息接口
  // userUrl: "/main/user",
  // 自定义解析接口用户信息
  // token传递的header名称
  tokenHeaderName: "x-access-token",
  // token存储的名称
  tokenStoreName: "token",
  // 获取缓存的token
  takeToken() {
    let token = localStorage.getItem(this.tokenStoreName);
    if (!token) {
      token = sessionStorage.getItem(this.tokenStoreName);
    }
    return token || null;
  },
  // 缓存token
  cacheToken(token?: string, remember = true) {
    localStorage.removeItem(this.tokenStoreName);
    sessionStorage.removeItem(this.tokenStoreName);
    if (token) {
      if (remember) {
        localStorage.setItem(this.tokenStoreName, token);
      } else {
        sessionStorage.setItem(this.tokenStoreName, token);
      }
    }
  },
  // 用户信息存储的名称
  userStoreName: "user",
  // 获取缓存的用户信息
  takeUser() {
    try {
      return (
        JSON.parse(localStorage.getItem(this.userStoreName) || "null") || null
      );
    } catch (e) {
      console.error(e);
    }
    return null;
  },
  // 缓存用户信息
  cacheUser(user?: Record<string, any>) {
    if (user) {
      localStorage.setItem(this.userStoreName, JSON.stringify(user));
    } else {
      localStorage.removeItem(this.userStoreName);
    }
  },

  // 缓存团队信息
  teamStoreName: "team",
  cacheTeam(team?: Record<string, any>) {
    if (team != null) {
      localStorage.setItem(this.teamStoreName, JSON.stringify(team));
    } else {
      localStorage.removeItem(this.teamStoreName);
    }
  },
  takeTeam() {
    try {
      return (
        JSON.parse(localStorage.getItem(this.teamStoreName) || "null") || null
      );
    } catch (e) {
      console.error(e);
    }
    return null;
  },
  // 缓存应用信息
  applyStoreName: "applyInfo",
  cacheApply(applyInfo?: Record<string, any>) {
    if (applyInfo != null) {
      localStorage.setItem(this.applyStoreName, JSON.stringify(applyInfo));
    } else {
      localStorage.removeItem(this.applyStoreName);
    }
  },
  takeApply() {
    try {
      return (
        JSON.parse(localStorage.getItem(this.applyStoreName) || "null") || null
      );
    } catch (e) {
      console.error(e);
    }
    return null;
  },
  // 首页路径, null会自动获取
  homePath: "/",
  loginPath: "/user/login",
  // 获取请求头
  getRequestHeader() {
    const { userId } = this.takeUser() || {};
    const token = this.takeToken();
    const { teamId } = this.takeTeam() || {};
    const { applicationId } = this.takeApply() || {};

    const headers: Record<string, any> = {};

    headers["x-platform"] = 10;
    if (token) headers[this.tokenHeaderName] = token;
    if (userId) headers["x-user-id"] = userId;
    if (teamId) headers["x-team-id"] = teamId;
    if (applicationId) headers["x-application-id"] = applicationId;

    return headers;
  },
};
