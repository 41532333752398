import { RouteRecordRaw, RouterView } from "vue-router";

export const packRoutes = (routes: Array<RouteRecordRaw>) => {
  return routes.map((route) => {
    if (route.children && route.children.length > 0) {
      const children = route.children.filter((it) => {
        return it.meta?.hide !== true;
      });
      if (!route.component) route.component = RouterView as any;
      if (!route.redirect && children.length > 0) {
        route.redirect = children[0].path;
      }
      packRoutes(route.children);
    }
    return route;
  });
};
