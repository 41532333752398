
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from "vue";
import { useThrottleFn } from "xz-use";

export default defineComponent({
  name: "TableWrap",
  props: {
    mode: {
      type: String as PropType<"normal" | "modal">,
      default: "normal",
    },
    search: {
      type: Object as PropType<{
        submit: () => void;
        reset: () => void;
      }>,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function as PropType<() => any>,
      default: () => {},
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const maxShowCount = ref(0);
    const tdHeight = 41;
    const headHeight = 48;

    const { run: resizeFn } = useThrottleFn(
      () => {
        const height = document.body.offsetHeight;
        maxShowCount.value = Math.ceil((height - headHeight) / tdHeight);
      },
      { wait: 500 }
    );

    const showBottomTableToolbar = computed(() => {
      const {
        pagination: { pageSize, current, totalPage, total },
      } = props;
      const currentCount =
        current < totalPage
          ? pageSize
          : totalPage > 1
          ? total - (totalPage - 1) * pageSize
          : total;
      return currentCount >= maxShowCount.value;
    });

    if (props.mode === "normal") {
      onMounted(() => {
        resizeFn();
        window.addEventListener("resize", resizeFn, false);
      });
      onUnmounted(() => {
        window.removeEventListener("resize", resizeFn, false);
      });
    }

    return {
      showBottomTableToolbar,
    };
  },
});
