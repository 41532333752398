import type { App } from "vue";

export default {
  install(app: App<Element>) {
    app.config.globalProperties.$log = console.log;
    app.config.globalProperties.$open = window.open;
    app.config.globalProperties.$parse = JSON.parse;
  },
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $log: typeof console.log;
    $open: typeof window.open;
    $parse: typeof JSON.parse;
  }
}
