import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    meta: {
      title: "用户",
      code: "ejn4h7ws",
    },
    component: RouterView,
    children: [
      {
        path: "/user/login",
        meta: {
          title: "登录",
          code: "t7b32myv",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/login/index.vue"),
      },
    ],
  },
];

export default routes;
