<script>
import { ref, onBeforeMount, computed } from "vue";
import { useRequest } from "xz-use";
import { SelectProps } from "ant-design-vue/es/select";
import omit from "omit.js";
import { ConsoleSqlOutlined } from "@ant-design/icons-vue";

export default {
  name: "BaseSelect",
  inheritAttrs: false,
  props: {
    service: { type: Function, default: () => Promise.resolve([]) },
    serviceArgs: {
      type: Array,
      default: () => [],
    },
    manual: {
      type: Boolean,
      default: false,
    },
    optionList: Array,
    optionListFilter: {
      type: Function,
      default: () => true,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    ...SelectProps(),
  },
  setup(props, { attrs, emit, slots, expose }) {
    const loading = ref(!props.optionList && !props.manual);
    const innerOptionList = ref([]);
    // antd Select 实例
    const selectRef = ref(null);

    // 设置内部的optionList并设置autoSelectFirst
    const setInnerOptionList = (data) => {
      innerOptionList.value = data;
      loading.value = false;
    };

    const {
      run,
      refresh,
      reset,
      loading: serviceLoading,
    } = useRequest(
      (...args) =>
        props.service(...(args && args.length ? args : props.serviceArgs)),
      {
        //设置手动 || 外部传入的值 = 此处不调用接口
        manual: true,
        initialData: [],
        formatResult: (data) => data || [],
        onSuccess: (data) => {
          setInnerOptionList(data);
        },
      }
    );

    onBeforeMount(() => {
      // 设置手动 || 外部传入的值 = 此处不调用接口
      if (!(props.manual || props.optionList)) {
        run();
      }
    });

    const list = computed(() => {
      let list = [];
      if (props.optionList) {
        list = props.optionList;
      } else {
        list = innerOptionList.value;
      }
      return list.filter(props.optionListFilter);
    });

    return () => {
      const selectProps = omit(props, [
        "service",
        "serviceArgs",
        "manual",
        "optionList",
        "optionListFilter",
        "autoSelectFirst",
        "labelKey",
        "valueKey",
      ]);

      return (
        <a-select {...selectProps} {...attrs} ref={selectRef}>
          {list.value.map((item) => {
            return (
              <a-select-option
                key={item[props.valueKey]}
                value={item[props.valueKey]}
                label={item[props.labelKey]}
              >
                {item[props.labelKey]}
              </a-select-option>
            );
          })}
        </a-select>
      );
    };
  },
};
</script>
