import { createFromIconfontCN } from "@ant-design/icons-vue";

const IconFont = createFromIconfontCN({
  scriptUrl: [
    "//at.alicdn.com/t/font_2039048_oj3rs28iztb.js", // 象征后台2.0
    "//at.alicdn.com/t/font_1808552_yxrf917ci1.js", // bbc小程序
    "//at.alicdn.com/t/font_1744814_6jc1w9ov7x.js", // 象征微商城图标
  ],
});

export default IconFont;
