import { httpGet, httpPost } from "@/config/axios-config";

// 登录
export type LoginParams =
  | {
      /** 登录类型 */
      loginType: 1;
      /** 密码 */
      password: string;
      /** 用户名 */
      username: string;
    }
  | {
      /** 登录类型 */
      loginType: 2;
      /** 手机号验证 */
      mobileCode: string;
      /** 手机号 */
      mobileNo: string;
      /** 图形验证码 */
      // imgCode: string;
    };
export interface LoginRes {
  hasPassword: boolean;
  tokenJson: TokenJson;
  userInfo: UserInfo;
}
export interface UserInfo {
  authorityCodeList?: any;
  hasPassword?: any;
  mobileAreaNo: string;
  mobileNo: string;
  nickName: string;
  platform: number;
  portraitUrl: string;
  source?: any;
  userId: string;
  userStatus: number;
  username: string;
}

export interface TokenJson {
  accessToken: string;
  expiresIn: number;
  jti: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
}
export interface StruInfo {
  pId?: any;
  stratum: number;
  struCode?: any;
  struId: string;
  struName: string;
  sysId: string;
  voList?: any;
  roleList: any[];
}
export interface SysInfo {
  address?: any;
  adminMobileNo: string;
  createDate: string;
  managementCategory: string;
  region: string;
  subjectName?: any;
  sysId: string;
  sysName: string;
  sysStatus: number;
  useEndTime: string;
}
export interface TeamInfo {
  /* 管理员手机号 */
  managerMobile: string;

  /* 团队说明 */
  teamContent: string;

  /* 团队ID */
  teamId: number;

  /* 团队名称 */
  teamName: string;
}
export const login = (params: LoginParams) =>
  httpPost<LoginRes>("/gmcuser/xz/login", params);

// 登出
export const logout = () => httpPost("/gmcuser/xz/logout");

// 获取当前登录用户的信息
export interface GetCurrentUserRes {
  authorityCodeList?: any;
  hasPassword: boolean;
  mobileAreaNo: string;
  mobileNo: string;
  nickName?: any;
  platform: number;
  portraitUrl?: any;
  source?: any;
  userId: string;
  userStatus: number;
  username: string;
}
export const getCurrentUser = () =>
  httpGet<GetCurrentUserRes>("/gmcuser/xz/userInfo/currentUser");

// 发送验证码
interface SendSmsParams {
  mobileNo: string;
  type: string;
}
export const sendSms = (params: SendSmsParams) =>
  httpGet("/common/msg/send", params);
